@use 'variables' as var;
.player {
    padding: 0 .75rem .25rem;
    margin: 0 .25rem .5rem .25rem;
    border-radius: .5rem;
    background-color: var.$primary-light;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    min-width: fit-content;
    &-champs {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-header{
        z-index: 1;
        position: sticky;
        top:0;
        background-color: var.$primary-light;
        padding-bottom: .25rem;
        margin-left: -.75rem;
        margin-right: -.75rem;
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        border-bottom: solid rgba(0, 0, 0, 0.25) 3px;
    }

    &-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: .5rem;
        margin: 0 .5rem 0 .5rem;
        padding-top: .5rem;
        

        &-basic {
            display: flex;
            &-base{
                height: 4.5rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 1rem;
            font-weight: bold;
            }
            &-stats{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: .25rem .5rem .25rem 1.25rem;
                table{
                    tr{
                        td{
                            padding: 0 .25rem;
                        }
                    }
                }
                
                .wr{
                    font-weight: bold;
                    color: var.$action-green;
                }
            }
            &-name{
                font-family: Arial, Helvetica, sans-serif;
                font-weight: normal;
                font-size: 1.5rem;
            }

            &-elo{
                color: var.$secondary-dark;
            }

            .tierImage{
                max-width: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                margin-left: .25rem;
                img{
                    max-height: 3.5rem;
                    margin: auto;
                }
            }

        }

        &-roles {
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-collaps{
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 1rem;
            svg{
                width: 1.5rem;
                .cls-1 {
                    fill: none;
                    stroke: var.$secondary-dark;
                    stroke-miterlimit: 10;
                    stroke-width: 5px;
                  }
            
                  .cls-2 {
                    fill: var.$action-blue;
                  }
            }
        }
    }
}

.RoleStats {
    font-family: AgencyFB;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    .RoleStats-Img-notActive{
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    
    span{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 4rem;
    }

    img {
        width: 3.5rem;
        height: 3.5rem;
    }

    svg {
        height: 4rem;

        text {
            fill: var.$secondary-light;
            stroke: 10px;
            font-weight: bold;
        }
    }
}

.champion {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    padding: .5rem;
    margin: .5rem;
    min-width: 20rem;
    background-color: var.$primary-normal;
    font-family: AgencyFB;
    border-radius: .5rem;

    &-head {
        display: flex;
        justify-content: space-between;
        height: 3rem;
        margin-bottom: .75rem;

        &-name {
            margin: 0 .25rem;
            font-size: 1.5rem;
            height: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
        }

        img {
            width: 3rem;
            height: 3rem;
        }
    }

    td {
        max-width: 4rem;
        min-width: 3.75rem;
        font-size: 1.25rem;
        text-align: center;
    }

    th{
        color: var.$action-blue;
        font-size: 1.5rem;
    }

    &-stats {
        font-weight: bold;

        &-first {
            border-right: solid;
            border-color: transparent;
            border-width: 2px;
            min-width: 4.75rem;
            text-align: left !important;
            font-weight: bolder;
        }


        &-winrate {
            &-positiv {
                color: var.$action-green;
            }

            &-negativ {
                color: var.$action-red;
            }
        }

        &-kda {
            &-positiv {
                color: var.$action-green;
            }

            &-neutral {
                color: var.$action-orrange;
            }

            &-negativ {
                color: var.$action-red;
            }
        }

        &-kills {
            color: var.$action-green;
        }

        &-deaths {
            color: var.$action-red;
        }

        &-assists {
            color: var.$action-orrange;
        }

        &-gold {
            color: var.$action-gold;
        }

        &-visionScore{
            color: var.$action-lightblue;
        }

    }
}