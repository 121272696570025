@use 'variables' as var;
//reset :default
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 100vw;
}

body{
    font-family: AgencyFB, Arial, Helvetica, sans-serif;
    background-color: var.$primary-normal;
    color: var.$secondary-light;
    overflow-x: hidden;
}

.site{
    min-height: 100vh;
    display:flex; 
    flex-direction:column;
    justify-content: space-between;
}

input{
    font-family: Arial, Helvetica, sans-serif;
}

textarea{
    font-family: Arial, Helvetica, sans-serif;
}

p{
    font-family: Arial, Helvetica, sans-serif;
}

.ERROR{
    color: var.$action-red-hard;
}

.resetFont{
    font-family: Arial, Helvetica, sans-serif;
}