@use 'variables' as var;
.CookieConsent {
    background: var.$primary-brighter !important;
    color: var.$secondary-normal !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    font-family: Helvetica, sans-serif, Arial;

    button {
        color: var.$primary-dark !important;
        background-color: var.$action-orrange !important;
        font-size: 13px;
    }

    span {
        font-size: 10px;
    }
}