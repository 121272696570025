@use 'variables' as var;

.summonerspell {
    padding: 0 .125rem;

    img {
        border-radius: 50%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
        max-width: 2rem;
    }

    &-tooltip {
        display: flex;
        flex-direction: column;
        font-family: Arial, Helvetica, sans-serif;

        &-header{
            display: flex;
            justify-content: space-between;
        }
        &-name {
            font-weight: bold;
            font-size: 1rem;
        }

        &-description {
            font-size: .75rem;
            max-width: 15rem;
        }

        &-cooldown{
            i{
                margin: .25rem;
            }
        }
    }
}