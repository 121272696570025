@use 'variables' as var;

//Dialog
.p-dialog-content{
    overflow-x: hidden !important;
}


//Accordion
.p-accordion {
    min-width: 10rem;
}

.p-accordion-header-link {
    color: var.$secondary-light;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin: .25rem 0;
}

.p-accordion-header-text {
    min-width: 6rem;
    margin: auto;
}

//multistate checkbox
.p-multistatecheckbox {
    .p-checkbox-box {
        margin: auto .25rem;
        width: 1.25rem;
        height: 1.25rem;
    }
    .p-checkbox-icon{
        background-color: transparent;
        border: solid 2px var.$secondary-dark;
        border-radius: 25%;
        margin: auto;
        font-size: .9rem;
        padding: .135rem;
        color: var.$secondary-light;
        font-weight: bold;
        max-width: 1.5rem;
        max-height: 1.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
    }
    .pi-check {
        color:  var.$action-green;
        
    }
    .pi-times {
        color:  var.$action-red;
    }
}


.p-dropdown-panel{
    .p-dropdown-items-wrapper{
        background-color: var.$primary-normal;
        border-radius: .25rem;
        box-shadow: 0 4px 8px 0 var.$primary-dark, 0 6px 20px 0 var.$primary-dark;
        padding: .2rem;
        z-index: 1;
        .p-dropdown-items{
            .p-dropdown-item{
                //font-family: Arial, Helvetica, sans-serif;
                font-size: 1.25rem;
                padding: .2rem;

                &:hover{
                    background-color: var.$primary-light;
                }
            }
        }
    }
}

.p-tabview-title{
    font-weight: bold;
    padding: 1rem 1rem .25rem 1rem;
}

.p-tabview-selected{
    color: var.$action-blue;
}