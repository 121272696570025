@use 'variables' as var;

.matchsidecard {
    background-color: rgba(33, 33, 33, 0.5);
    box-shadow: var.$boxshadow-default;
    border-radius: .5rem;
    padding: .5rem;
    margin: .25rem;
    border-left: solid 3px var.$primary-light;

    display: flex;

    &-color {
        &-blue {
            border-color: var.$action-blue;
        }

        &-red {
            border-color: var.$action-red;
        }
    }

    &-win {
        color: var.$action-gold;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-lost {
        color: var.$action-red;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.matchteamchamp {
    display: flex;
    flex-direction: column;
    margin: auto .5rem;

    &-header {
        margin: auto .25rem;

        &-img {
            display: flex;

            img {
                display: flex;
                width: 3rem;
            }

            &-activeplayer {
                img {
                    border-color: var.$action-gold;
                    border-width: 2px;
                }
            }
        }

        &-info {
            position: relative;
            bottom: 3.5rem;
            display: block;
            width: 0;
            height: 0;

            button {
                border: none;
                border-radius: 50%;
                width: fit-content;
                height: fit-content;
                background-color: var.$primary-normal;
                color: var.$secondary-normal;
                box-shadow: var.$boxshadow-default;
                transition: ease-in-out .2s;

                &:hover {
                    background-color: var.$primary-light;
                }

                span {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    width: 1.25rem;
                    height: 1.25rem;
                    font-size: 1rem;
                }
            }
        }
    }

    &-kda {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding-top: .25rem;
        font-weight: bold;

        &-main {
            font-size: 1rem;
            margin: auto;
        }

        &-calculated {
            display: flex;
            justify-content: space-around;
            font-size: .9rem;

            b {
                margin: auto .125rem;
            }
        }
    }

    &-info {
        background-color: rgba(22, 27, 34, 0.85);
        box-shadow: var.$boxshadow-default;
        padding: .5rem;
        border-radius: .5rem;

        .p-link {
            color: var.$secondary-light;
        }

        &-card {
            display: flex;
            padding: .5rem;
            margin: .25rem;
        }

        &-header {
            font-family: Arial, Helvetica, sans-serif;
            padding: .5rem;
            padding-bottom: 0;
            margin: auto;

            span {
                margin-left: .5rem;

                a {
                    color: var.$secondary-light;
                }
            }
        }
    }
}