@font-face {
    font-family: 'aBlackLives';
    src: url('../assets/fonts/aBlackLives.ttf') format('truetype');
}

@font-face {
    font-family: 'AgencyFB';
    font-weight: bold;
    src: url('../assets/fonts/AgencyFB-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'AgencyFB';
    font-weight: normal;
    src: url('../assets/fonts/AgencyFB-Light.ttf') format('truetype');
}