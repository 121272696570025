@use 'variables' as var;
.header {
    background-color: var.$primary-dark;
    padding: .25rem .5rem;

    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 20% 60% 20%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 60;

    &-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        a {
            color: var.$secondary-normal;
            text-decoration: none;

            svg {
                padding-top: .5rem;
                max-height: 2.5rem;
                max-width: 2.5rem;
            }
        }
    }

    a{
        text-decoration: none;
    }
    &-logo {
        font-size: 2.5rem;
    }

    &-pages {
        display: flex;
        flex-direction: row;
        justify-content: center;

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 1rem;

            a {
                display: flex;
                flex-direction: column;
                font-size: 1.75rem;
                font-weight: bold;
                padding: .25rem;
                color: var.$secondary-normal;
                text-decoration: none;
            }
        }

        &-active{
            a{
            color: var.$action-lightblue !important;
            }
        }
    }

}