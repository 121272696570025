@use 'variables' as var;
.ChampStatsOverview{
    display: flex;
    flex-wrap: wrap;

    &-Champ{
        min-width: 10rem;
        display: flex;
        margin: .25rem;

        &-Tooltip-img{
            max-width: 2rem;
            margin: 0 .25rem;
        }

        &-Stats{
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            table{
                tr{
                    td{
                        padding: 0 .125rem;
                    }
                }
            }
            
        }

        &-Img{

            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: .25rem;

            .champimg{
            max-width: 2.75rem;
            max-height: 2.75rem;
            
            img{
                display: flex;
                max-width: 2.75rem;
                max-height: 2.75rem;
            }
        }
        }
        
        
    }

    &-collaps{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: .5rem;
        svg{
            width: 2rem;
            height: 2rem;

            .cls-1{
                stroke: var.$primary-brighter;
                stroke-width: 8px;
            }

            .cls-2{
                fill: var.$action-blue;
            }
        }
    }
}