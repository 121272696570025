@use 'variables' as var;
.home{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 120px 1fr 0px;

    &-side{
        grid-column: 3;
    }
    &-main{
        grid-column: 1 / 3;
        width: 100%;
    }
}

@media (max-width: var.$mediabreakpoint-tablet) {
    .home{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

}