@use 'variables' as var;

.tooltip-item{
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    &-name{
        display: flex;
        &-img{
            margin: .25rem;
            img{
                width: 3rem;
                border-radius: .5rem;
            }
        }

        &-header{
            display: flex;
            flex-direction: column;
            margin: .25rem;
            &-name{
                font-size: 1rem;
            }

            &-cost{
                font-size: .8rem;
                i{
                    margin-right: .25rem;
                }
            }
        }
    }

    &-description{
        max-width: 20rem;
        margin: 0;
    }
}