@use 'variables' as var;
.tag{
    padding: .2rem .75rem;
    margin: auto .25rem;
    border: none;
    border-radius: .25rem;
    color: var.$primary-dark;
    box-shadow: var.$boxshadow-default;
    font-size: .8rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;

    &-red{
        background-color: var.$action-red-hard;
        color: var.$secondary-light;
    }
    &-green{
        background-color: var.$action-green;
    }
    &-blue{
        background-color: var.$action-blue;
    }
    &-purple{
        background-color: var.$action-purple;
    }
    &-yellow{
        background-color: var.$action-gold;
    }
    &-orange{
        background-color: var.$action-orrange;
    }
    &-gray{
        background-color: var.$secondary-normal;
    }
    &-redside{
        background-color: var.$action-red-dark;
        color: var.$secondary-light;
    }

    &-roletag{
        max-width: fit-content;
        margin: auto .75rem;
        .role-img-wrapper{
            display: flex;
            img{
                position: absolutere;
                max-width: 2.5rem;
            }
        }
    }
}