@use 'variables' as var;

.match {

    padding: 1rem;
    border-radius: .25rem;
    margin: .5rem 0;

    &-headline {
        display: flex;

        &-time {
            align-self: center;
            margin: auto .25rem auto 0;
            font-weight: bold;
        }

        &-tags {
            display: flex;
            justify-content: left;
        }

        &-info {
            button {
                border: none;
                border-radius: 50%;
                width: fit-content;
                height: fit-content;
                background-color: var.$primary-normal;
                color: var.$secondary-normal;
                box-shadow: var.$boxshadow-default;
                transition: ease-in-out .2s;

                &:hover {
                    background-color: var.$primary-light;
                }

                span {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    width: 1.25rem;
                    height: 1.25rem;
                    font-size: 1rem;
                }
            }
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

    &-win {
        background-color: var.$dark-green;
    }

    &-lost {
        background-color: var.$dark-red;
    }

    &-content {
        display: flex;
        flex-wrap: wrap;

        &-player {
            display: flex;
        }
    }

    &-fulldata {
        display: flex;
        justify-content: space-around;

        &-tag{
            display: flex;
            margin: auto .5rem;
            justify-content: center;
            span{
                margin: auto .25rem;
            }

            &-link-blue{
                a{
                    color: var.$primary-dark;
                }
            }
            &-link-red{
                a{
                    color: var.$secondary-light;
                }
            }
        }

        &-header{
            display: flex;
            justify-content: space-between;

            margin: .5rem;
        }
        &-wrapper {
            display: flex;
            flex-direction: column;
            min-width: max-content;
        }

        &-side {
            margin: .25rem;
            border-radius: .5rem;
            padding: .5rem;

            &-blue {
                border: solid 2px var.$action-blue;
            }
            &-red {
                border: solid 2px var.$action-red;
            }

            &-card {
                margin: .25rem;
                padding: .25rem;
                background-color: var.$primary-dark;
                border-radius: .5rem;
                

                &-header {
                    display: flex;
                    span{
                        font-family: Arial, Helvetica, sans-serif;
                        margin-left: .5rem;

                        a{
                            color: var.$secondary-light;
                        }
                    }
                }

                &-content {
                    display: flex;
                }
            }
        }
    }
}