@use 'variables' as var;
.banhighlighter {

    h3{
        font-family: AgencyFB;
        font-weight: bold;
        font-size: 2rem;
        margin: .5rem;
    }

    &-content {
        display: flex;
        flex-direction: row;
        max-width: fit-content;
        flex-wrap: wrap;
        max-width: 90vw;
        span {
            max-height: 5rem;
            max-width: 5rem;
            margin: .5rem;
            transform: scale(0.975);
        }
    }
}