@use 'variables' as var;
.Player-Details {
    margin: 1rem;
    background-color: var.$primary-normal;
    border-radius: .5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    &-Basic {
        display: flex;
        flex-wrap: wrap;
        &-Data {
            margin: 1rem;
            .tierImage{
                display: flex;
                justify-content: center;
                margin-top: .25rem;
                img{
                    width: 6rem;
                }
            }

            &-Parts{
                display: flex;
            }

            &-Part {
                display: flex;
                flex-direction: column;
                margin-top: .5rem;

                &-Element {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 1rem;
                    span{
                        margin: 0 .5rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &-Mastery{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        &-Wrapper{
            margin: 1rem;
        }
        &-Imgs{
            display: flex;
            &-img{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: .25rem;
                img{
                    display: flex;
                    width: 5rem;
                    height: fit-content;
                }
            }
        }

        &-Details{
            display: flex;
            flex-direction: column;
        }


    }

    .data {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        color: var.$action-blue;
    }
}

.Tabview-Controls{
    &-Element{
        margin: .5rem;
        font-weight: bold;
        cursor: pointer;
        &-Active{
            color: var.$action-blue;
        }
    }
}