@use 'variables' as var;
.howItWorks {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;

    &-content {
        display: flex;
        flex-direction: column;

        &-sections {
            padding: .75rem;
            margin: .25rem;
            border-radius: .5rem;

            .p-accordion-header-link {
                display: flex;
                justify-content: left;

                .p-accordion-header-text {
                    margin: .25rem;
                }
            }

            .p-accordion-tab {
                padding: .5rem;
                margin: .5rem 0;
                border-radius: .5rem;
                background-color: var.$primary-light;
                box-shadow: var.$boxshadow-default;
            }

            .p-accordion-tab-active {
                background-color: var.$primary-light;
                box-shadow: var.$boxshadow-default;

                .p-accordion-header-text {
                    color: var.$action-lightblue;
                }
            }

            &-tab-content {
                display: flex;
                flex-direction: column;
                margin: .5rem .5rem .25rem .5rem;
                font-size: 1.2rem;
                font-family: "Roboto Mono", monospace;

                p {
                    padding: .5rem 0;
                }

                b {
                    color: var.$action-blue;
                }

                .red {
                    color: var.$action-red;
                }

                .orange {
                    color: var.$action-orrange;
                }

                .green {
                    color: var.$action-green;
                }

                .center {
                    display: flex;
                    justify-content: center;
                }

                table {
                    max-width: fit-content;
                    tr {
                        td {
                            padding: 0 .5rem;
                        }
                    }
                }

                img {
                    margin: 1rem 0;
                    width: 80%;
                    max-width: max-content;
                    align-self: center;
                    box-shadow: var.$boxshadow-default;
                    border-radius: .5rem;
                }
            }
        }
    }


    &-headline {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: .5rem 0;
        margin-top: 4rem;
    }
}