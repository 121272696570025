@use 'variables' as var;
.logo{
    
    &-rival{
        color: var.$secondary-light;
        font-weight: bold;
        font-family: AgencyFB, Arial, Helvetica, sans-serif;
    }

    &-scout{
        font-family: aBlackLives, Arial, Helvetica, sans-serif;
        color: var.$action-blue;
    }
}