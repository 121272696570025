@use 'variables' as var;
.champimg{

    border-radius: 50%;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);

    &-ban{
        opacity: 0.5;
        border-radius: 50%;
        border-color: black;
        border-style: solid;
        border-width: .1rem;
        max-width: 5rem;
    }

    &-compchampselector{
        opacity: 0.5;
        border-radius: 50%;
        border-color: black;
        border-style: solid;
        border-width: .1rem;
        max-width: 5rem;
        
    }

    &-default{
        border-radius: 50%;
        border-color: black;
        border-style: solid;
        border-width: .1rem;
        max-width: 5rem;
    }

    &:hover{
        background-color: var.$action-red;

        .champimg-default{
            opacity: 0.5;
        }
    }


    &-banspan{
        background-color: var.$action-red-hard;

        &:hover{
            background-color: var.$action-red-hard;

        }
    }

    &-compchampselectorspan{
        background-color: var.$action-green-soft;
        &:hover{
            background-color: var.$action-green;

        }
    }
}




.champimg-compchampselectorspan{

    &-special:hover{
        background-color: var.$action-green;
    }
}
