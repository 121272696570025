@use 'variables' as var;
.slider{
    appearance: none;
    background-color: var.$secondary-dark;
    height: .2rem;
    margin: .5rem 0;
    border-radius: .2rem;
}

.slider::-moz-range-progress{
  appearance: none;
  border-radius: .2rem;
  height: .25rem;
  background-color: var.$action-blue;
}

.slider::-webkit-slider-thumb{
    appearance: none;
    width: 1rem;
    height: 1rem;
    background-color: var.$action-blue;
    border-radius: 50%;
}

.checkbox{
    appearance: none;
    width: 1rem;
    height: 1rem;
    margin: auto 0;
    background-color: var.$secondary-dark;
    border-radius: 25%;
}

.checkbox:checked{
    background-color: var.$action-green;
}

.checkbox:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: var.$secondary-normal;
}
.checkbox:hover:checked{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: var.$action-green;
}



.loading-icon{
        animation: rotation 3s infinite linear;
        width: 5rem;
        height: 5rem;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }



  input[type="date"]{
      box-shadow: 0 4px 8px 0 var.$primary-dark, 0 6px 20px 0 var.$primary-dark;
      background-color: var.$secondary-dark;
      padding: .25rem;
      font-family: "Roboto Mono",monospace;
      color: var.$primary-dark;
      font-size: 1rem;
      border: none;
      outline: none;
      border-radius: 5px;
  }
  ::-webkit-calendar-picker-indicator{
      background-color: var.$secondary-dark;
      padding: 5px;
      cursor: pointer;
      border-radius: 3px;
  }




  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .react-switch-label:checked {
    background: var.$action-blue;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 3rem;
    height: 1.5rem;
    border-radius: 5rem;
    position: relative;
    transition: background-color .5s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 4.5rem;
    transition: 0.5s;
    background-color: var.$secondary-light;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-label-off{
    background: var.$secondary-dark;
  }

  .react-switch-label-on{
    background: var.$action-blue;
  }


  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    transform: translateX(100%);
  }
  





//https://www.w3schools.com/cssref/tryit.php?filename=trycss_cursor
.mouse-pointer {
    cursor: pointer;
}