//colors
$primary-dark: #0d1117;
$primary-normal: #161b22;
$primary-light: #21262d;
$primary-brighter: #262d37;

$secondary-dark: #89929b;
$secondary-normal: #c6cdd5;
$secondary-light: #ecf2f8;

$action-red: #fa7970;
$action-red-hard: #ff392b;
$action-red-dark: #a53027;
$action-orrange: #faa356;
$action-gold: #faf756;
$action-green: #7ce38b;
$action-green-soft: #a5f6b1;
$action-lightblue: #a2d2fb;
$action-blue: #77bdfb;
$action-purple: #cea5fb;


$dark-green: rgba(124, 227, 139, .5);
$dark-red: rgba(255, 57, 43, .5);
// MediaBreakpoints

$mediabreakpoint-tablet: 1280px;
$mediabreakpoint-mobile: 428px;

//boxshadow
$boxshadow-default: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);