@use 'variables' as var;

.matchplayercard {
    display: flex;
    background-color: rgba(33, 33, 33, 0.5);
    box-shadow: var.$boxshadow-default;
    border-radius: .5rem;
    padding: .5rem;
    margin: .25rem;
    border-left: solid 3px var.$primary-light;
    &-overview {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 .25rem;

        &-img {
            display: flex;
            justify-content: center;
            margin: .25rem 0 .1rem 0;

            .champimg {
                max-width: 3rem;
                max-height: 3rem;

                img {
                    display: flex;
                    max-width: 3rem;
                    max-height: 3rem;
                }
            }

            &-active{
                img{
                    border-color: var.$action-gold;
                    border-width: 2px;
                }
            }

            &-runes {
                z-index: 0;
                position: relative;
                right: 1.25rem;
                top: 1.75rem;
                width: 0;
                height: 0;

                &-img {
                    background-color: var.$primary-normal;
                    width: 1.5rem;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.5);
                }
            }
        }

        &-sums {
            margin-top: .25rem;
        }
    }

    &-content {
        display: flex;

        &-kda {
            margin: auto .5rem;
            display: flex;
            flex-direction: column;
            width: 5.5rem;
            &-main {
                font-size: 1.5rem;
                font-weight: bold;
                margin: auto;
            }

            &-calculated {
                font-size: 1rem;
                margin: auto;
            }
        }

        &-items {
            display: flex;

            .itemImg {
                border-radius: .5rem;
                width: 1.75rem;
                margin: .25rem;
            }

            &-default {
                margin: auto;
                max-width: 6.75rem;
                display: flex;
                flex-wrap: wrap;
            }

            &-vision {
                display: flex;
                flex-direction: column;
                margin: auto .25rem;

                &-img-wrapper {
                    .itemImg {
                        border-radius: 50%;
                        width: 2rem;
                        margin: auto .25rem;
                    }
                }

                &-txt-wrapper {
                    margin: auto;
                    display: flex;
                    justify-content: space-around;

                    span {
                        margin: auto;
                        font-size: medium;
                    }

                    i {
                        margin: .2rem;
                        display: flex;
                        justify-content: center;
                        font-size: .75rem;
                    }
                }
            }
        }

        &-bonus {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: auto .25rem;
            font-size: 1rem;

            &-stat{
                width: 6em;;
            }
        }
    }
}

.tooltip-runes {
    display: flex;

    &-main {
        display: flex;
        flex-direction: column;
    }

    &-secundary {
        display: flex;
        flex-direction: column;

        &-perks {
            display: flex;
            flex-direction: column;
            background-color: var.$primary-light;
            padding: .25rem;
            border-radius: .5rem;
        }
    }

    .rune {
        display: flex;
        flex-direction: column;
        margin: .25rem;
        background-color: var.$primary-normal;
        padding: .25rem;
        border-radius: .5rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: .9rem;

        &-header {
            display: flex;

            &-txt {
                font-size: 1.25rem;
                margin: auto .25rem;
            }
        }

        &-img {
            width: 2.5rem;
            height: 2.5rem;
            margin: auto .75rem;
        }

        &-txt {
            max-width: 25rem;
            margin: .25rem;
        }

        &-main {

            background-color: var.$primary-dark;

            .rune-img {
                width: 4rem;
                height: 4rem;
                margin: auto 0;
            }

            .rune-header-txt {
                font-size: 1.5rem;
            }
        }

        &-stat {
            font-family: Arial, Helvetica, sans-serif;
            font-size: .9rem;
            display: flex;

            .rune-txt {
                margin: auto .25rem;
            }

            .rune-img {
                width: 1.5rem;
                height: 1.5rem;
                margin: auto 1.25rem;
            }
        }

    }
}