@use 'variables' as var;
.tracker {

    min-height: 5rem;
    padding: 0 1rem;
    margin: auto 2rem;
    font-size: 1.25rem;
    min-width: 90vw;

    &-filter {
        margin: 1rem 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: var.$primary-dark;
        padding: .5rem;
        border-radius: .5rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
        min-width: fit-content;

        &-btn {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            button {
                height: 2rem;
                padding: .25rem .75rem;
                margin: .5rem;
                margin-top: 2rem;
                border-radius: .25rem;
                border: none;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
                background-color: var.$primary-light;
                color: var.$secondary-light;
                font-weight: bold;
                font-size: 1.25rem;
            }

            button:hover{
                background-color: var.$primary-normal;
            }


        }

        .copybtn {
            background-color: var.$primary-light;
            transition: background-color 0.25s;
            font-size: .75rem;
            padding: .5rem .75rem;
        }

        .copybtn:hover {
            background-color: var.$primary-normal;
        }

        .copybtn:active {
            background: var.$action-green;
        }



        &-input {
            max-height: 5.5rem;

            display: flex;
            flex-direction: column;
            margin: .5rem;

            &-label {
                height: 1.5rem;
                font-weight: bold;
                margin-bottom: .25rem;

                &-slider {
                    min-width: 12rem;
                }
            }

            &-text {

                max-height: 5rem;
                max-width: 25rem;
                height: 2rem;
                width: 15rem;
                padding: .25rem;
                border-radius: .25rem;
                border: none;
                box-shadow: 0 4px 8px 0 var.$primary-dark, 0 6px 20px 0 var.$primary-dark;
                background-color: var.$secondary-dark;
                color: var.$primary-dark;
                font-size: 1rem;
            }

            &-games {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: .5rem;

                span {
                    margin: .25rem;
                    display: flex;
                    flex-direction: column;
                    margin: 0 .75rem;
                }

                b {
                    color: var.$action-blue;
                    font-weight: bold;
                }

                &-number {

                    max-height: 2rem;
                    max-width: 6rem;
                    height: 2rem;
                    width: 15rem;
                    padding: .25rem;
                    border-radius: .25rem;
                    border: none;
                    box-shadow: 0 4px 8px 0 var.$primary-dark, 0 6px 20px 0 var.$primary-dark;
                    background-color: var.$secondary-dark;
                    color: var.$primary-dark;
                    font-size: 1rem;
                }
            }

            &-region{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            } 

            .region-dropdown {
                height: 2rem;
                max-height: 5rem;
                min-width: 11rem;
                background-color: var.$primary-light;
                padding: .2rem;
                border-radius: .25rem;
                font-size: 1.25rem;
                font-weight: bold;
                box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.2);
                z-index: 2;
            }



        }

    }

    &-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &-stats {
            display: flex;
            flex-direction: column;
            border-radius: .5rem;
            padding: .5rem;
            max-width: 100%;
            // width: 90%;
            &-wrapper{
                width: 100%;
            }
        }

        &-filter {
            background-color: var.$primary-dark;
            margin-top: .75rem;
            margin-right: .25rem;
            padding: .5rem;
            border-radius: .5rem;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
            height: max-content;
            min-width: fit-content;

            &-element {
                display: flex;
                flex-direction: column;
                padding: 1rem 0;

                b {
                    color: var.$action-blue;
                    font-weight: bold;
                }

                &-filter {
                    &-slider {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        margin: .5rem 0;

                        label {
                            padding: 0;
                            margin: 0;
                        }
                    }

                    &-checkbox {
                        display: flex;
                        flex-direction: row;

                        justify-content: space-between;
                        width: 100%;
                        height: 1.75rem;
                        margin: .25rem 0;

                        label {
                            height: 1rem;
                            padding: 0;
                            margin: 0;
                            padding-right: .75rem;
                            font-size: 1.5rem;
                        }

                        input {
                            margin: auto .5rem;
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }
                }

                &-number {

                    max-height: 2rem;
                    max-width: 6rem;
                    height: 2rem;
                    padding: .25rem;
                    border-radius: .25rem;
                    border: none;
                    box-shadow: 0 4px 8px 0 var.$primary-dark, 0 6px 20px 0 var.$primary-dark;
                    background-color: var.$secondary-dark;
                    color: var.$primary-dark;
                    font-size: 1rem;
                }


                &-champs{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    max-width: 10.5rem;
                    span{
                        display: flex;
                        margin: .25rem;
                        img{
                            width: 3rem;
                        }
                    }
                }
            }
        }

    }

    &-content-box {
        // box-shadow: 0 4px 8px 0 var.$primary-dark, 0 6px 20px 0 var.$primary-dark;
        margin: .75rem;
        padding: 1rem;
        height: 100%;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        flex-direction: row;



        &-intro {
            //font-weight: bold;
            padding-top: 2.5rem;
            font-size: 2.5rem;
            text-align: center;
            width: 75%;

            &-bild {
                img {
                    max-width: 18rem;
                }
            }

            &-text {
                font-size: 3.75rem;
            }
        }



        .loading-icon {
            margin: auto;
        }
    }

}



@media (max-width: var.$mediabreakpoint-tablet) {
    .tracker-content {
        flex-direction: column;
    }

}