@use 'variables' as var;

.footer {
    background-color: var.$primary-dark;
    padding: .25rem .5rem;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    a {
        color: var.$secondary-normal;
        text-decoration: none;
    }

    span{
        font-size: .5rem;
        font-family: Arial, Helvetica, sans-serif;
        color: var.$secondary-dark;
        display: flex;
        flex-direction: column;
    }

}

