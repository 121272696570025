@use 'variables' as var;
.Comps{
    margin: .25rem 0;
    border-radius: .5rem;
    min-width: fit-content;
    display: flex;
    flex-wrap: wrap;
}

.CompCard{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    padding: .5rem;
    margin: .5rem;
    background-color: var.$primary-light;
    font-family: AgencyFB;
    border-radius: .5rem;
    &-Stats{
        display: flex;
        flex-direction: column;
        margin-bottom: .25rem;
    }
    &-Comp-small{
            display: flex;
            flex-direction: row;
            justify-content: center;
            span{
                display: flex;
                margin: .25rem;
                img{
                    width: 4rem;
                }
            }
    }
}

.CompPart{
    &-name{
        font-family: Arial, Helvetica, sans-serif;
        font-size: small;
        width: 7rem;
        vertical-align: middle;
        text-align: center;
        padding: 0 .5rem;
    }

    &-champ{
        vertical-align: middle;
        span{
            display: flex;
            margin: .25rem;
            img{
                width: 4rem;
            }
        }
    }

    

    &-damage{
        color: white;
        vertical-align: middle;
        padding: 0 .5rem;
        div{
            svg{
                width: 4.5rem;
                height: 4.5rem;

                text{
                    fill: white;
                }
            }
        }
    }
}